.maintheme-dark-color{
    background-color: #38383B;
}


.headingtext-main, .headingtext-regular {
    /* width: fit-content; */
    font-family: 'Lustria', serif;
    font-size: 18px !important ;
    font-weight: normal;
    padding: 5px;
    border-radius: 5px;

}

.headingtext-main {
    color: #38383B;
    background-color: #FFFFFF;
}
.headingtext-regular{
    background-color: #38383B;
    color: #FFFFFF;
}

.bodytext-participant, .bodytext-regular-body {
    /* width: fit-content; */
    font-family: 'Arimo', sans-serif;
    font-size: 12px !important ;
    font-weight: normal;
    color: #38383B;
    /* text-align: left; */
    padding: 5px;
}

.bodytext-participant {
    font-size: 14px !important ;
}

.bodytext-regular-body-white {
    color: #FFF;
}
/* .regular{
    background-color: #38383B;
    color: #FFFFFF; 
} */
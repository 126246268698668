.modal-content{
    background-color: #38383B;
    color: #FFF;
}


.modalbox-header
{
    font-family:  'Lustria', serif;;
}

.modalbox-body{
    font-family: 'Arimo', sans-serif;
}

.modalbox-footer
{
    font-family: 'Arimo', sans-serif;
}

.modalbox-buttons{
    border-radius: 10px;
}
.participant, .regular-body {
    width: fit-content;
    font-family: 'Arimo', sans-serif;
    font-size: 12px !important ;
    font-weight: normal;
    color: #38383B;
    text-align: left;
    padding: 0px;
    margin: 0px;
}

.participant {
    font-size: 14px !important ;
}

.regular-body-white {
    color: #FFF;
}
/* .regular{
    background-color: #38383B;
    color: #FFFFFF; 
} */
.main, .regular {
    width: fit-content;
    font-family: 'Lustria', serif;
    font-size: 18px !important ;
    font-weight: normal;
    text-align: left;
    padding: 5px;
    border-radius: 5px;
    margin: 0;

}

.main {
    color: #38383B;
    background-color: #FFFFFF;
}
.regular{
    background-color: #38383B;
    color: #FFFFFF;
}